/* Sora font */
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@200;300;400;500;600;700;800&display=swap");

/* DM Sans */
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

/* Blatant font */
@font-face {
  font-family: "Blatant";
  src: url("../public/font/Blatant.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Sora", sans-serif;
  overflow-x: hidden;
}

/* Colors */
:root {
  --black: #474745;
  --gray: #808080;
  --gray-light: #c7c7c6;
  --beige: #f8f6f3;
  --blue: #37c1d7;
  --yellow: #fbc70c;
}

/* Typography */
h1 {
  font-family: "Blatant", sans-serif;
  font-size: 4.209rem;
  font-weight: 400;
  color: var(--black);
  line-height: 1.1;
}

h2 {
  font-family: "Blatant", sans-serif;
  font-size: 3.157rem;
  font-weight: 400;
  line-height: 1.1;
  color: var(--black);
}

h3 {
  font-family: "Blatant", sans-serif;
  font-size: 2.369rem;
  font-weight: 400;
  color: var(--black);
  line-height: 1.1;
}

h4 {
  font-family: "Blatant", sans-serif;
  font-size: 1.777rem;
  font-weight: 400;
  color: var(--black);
}

h5 {
  font-size: 1.333rem;
  font-weight: 300;
  line-height: 1.6;
  letter-spacing: -0.005em;
  /* letter-spacing: 0.024em; */
  margin: 0;
  color: var(--black);
}

h6 {
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.75;
  /* letter-spacing: 0.024em; */
  margin: 0;
  color: var(--black);
}

p {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.6;
  color: var(--black);
}

.caption {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.0132em;
}

.overline {
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.0132em;
  text-transform: uppercase;
  color: var(--gray);
}

.navLinks {
  font-family: "Blatant", sans-serif;
  font-size: 1.333rem;
  font-weight: 400;
  letter-spacing: 0.024em;
  color: var(--black);
}

a {
  text-decoration: none;
}

li {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.6;
  letter-spacing: -0.005em;
  /* letter-spacing: 0.024em; */
  color: var(--black);
}

.about,
.creations {
  background-color: var(--beige);
}

.blackBg {
  background-color: var(--black);
}

.whiteBg {
  background-color: white;
}
